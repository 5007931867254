
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import { apiCheckLegal } from '@/api/home'

@Component({
    components: {
        LsDialog
    }
})
export default class IsCopyRight extends Vue {
    $refs!: { lsDialog: any }
    checking = true
    flag = true

    openDialog() {
        this.$refs.lsDialog.open()
        setTimeout(() => {
            this.checkRefresh()
        }, 1000)
    }

    goPage() {
        window.open('https://www.shop.cn/', '_blank')
    }

    checkRefresh() {
        apiCheckLegal()
            .then(res => {
                this.flag = res.result
            })
            .catch(err => this.$message.error(err))
            .finally(() => {
                this.checking = false
            })
    }
}
